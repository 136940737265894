import React from 'react';
import { FaGraduationCap, FaUniversity, FaLinkedin, FaEnvelope } from 'react-icons/fa';
import hadiPic from '../assets/hadipic.jpeg';
import lanaPic from '../assets/lanaAvatar.webp'
import guyAvatar from '../assets/guyavatar.jpg';

const tutors = [
  {
    name: "Abd Alhadi Abou Swid",
    university: "Hull York Medical School",
    degree: "MBBS Medicine",
    image: hadiPic,
    linkedin: "https://www.linkedin.com/in/abd-alhadi-abou-swid-0487b8278/",
    email: ""
  },
  {
    name: "Lana Abou Swid",
    university: "King's College London",
    degree: "MBBS Medicine",
    image: lanaPic,
    linkedin: "https://www.linkedin.com/in/lana-abou-swid-52a87a314/",
    email: ""
  },
  {
    name: "Abdulqadir Maricar",
    university: "Hull York Medical School",
    degree: "Medicine",
    image: guyAvatar,
    linkedin: "",
    email: ""
  }
];

const TutorCard = ({ tutor }) => (
  <div className="bg-gray-900 shadow-lg rounded-lg overflow-hidden transform transition duration-500 hover:scale-105 border border-gold-500">
    <div className="p-6 flex flex-col items-center">
      <div className="w-40 h-40 rounded-full overflow-hidden mb-4 border-2 border-gold-500">
        <img
          src={tutor.image}
          alt={tutor.name}
          className="w-full h-full object-cover"
        />
      </div>
      <h3 className="text-xl font-bold text-gold-500 mb-2">{tutor.name}</h3>
      <div className="flex items-center mb-2">
        <FaUniversity className="text-gold-500 mr-2" />
        <p className="text-sm text-gold-300">{tutor.university}</p>
      </div>
      <div className="flex items-center mb-4">
        <FaGraduationCap className="text-gold-500 mr-2" />
        <p className="text-sm text-gold-300">{tutor.degree}</p>
      </div>
      <div className="flex justify-center space-x-4">
        <a href={tutor.linkedin} className="text-gold-500 hover:text-gold-300" target="_blank" rel="noopener noreferrer">
          <FaLinkedin size={24} />
        </a>
        {tutor.email && (
          <a href={`mailto:${tutor.email}`} className="text-gold-500 hover:text-gold-300">
            <FaEnvelope size={24} />
          </a>
        )}
      </div>
    </div>
  </div>
);

const Team = () => {
  return (
    <div className="bg-gradient-to-b from-black via-gray-900 to-black py-16" id="team">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl text-center text-gold-500 font-bold mb-12">Meet Our Expert Tutors</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {tutors.map((tutor, index) => (
            <TutorCard key={index} tutor={tutor} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Team;