import React, { useState } from 'react';
import { Check } from 'lucide-react';

const Contact = () => {
  const initialFormData = {
    fullName: '',
    email: '',
    phoneNumber: '',
    services: {
      tutoring: false,
      coaching: false,
      testPrep: false,
    },
    tutoringLevel: '',
    subjects: [],
    notes: '',
  };

  const [formData, setFormData] = useState(initialFormData);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const subjects = {
    'A Level': ['Mathematics', 'Chemistry', 'Biology', 'Arabic'],
    'GCSE': ['Mathematics', 'Biology', 'Chemistry', 'Physics', 'Arabic'],
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleServiceChange = (e) => {
    const { name, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      services: {
        ...prevState.services,
        [name]: checked
      }
    }));
  };

  const handleSubjectChange = (subject) => {
    setFormData(prevState => ({
      ...prevState,
      subjects: prevState.subjects.includes(subject)
        ? prevState.subjects.filter(s => s !== subject)
        : [...prevState.subjects, subject]
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const requestData = {
      fullName: formData.fullName,
      email: formData.email,
      phoneNumber: formData.phoneNumber,
      services: {
        tutoring: formData.services.tutoring,
        coaching: formData.services.coaching,
        testPrep: formData.services.testPrep,
      },
      tutoringLevel: formData.tutoringLevel,
      subjects: formData.subjects,
      notes: formData.notes,
    };
    const url = "https://tuitionwebsitebackend-production.up.railway.app/api/submit-form";
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Success:', data);
        setIsSubmitted(true);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error:', error);
        setIsLoading(false);
      });
  };

  const handleNewForm = () => {
    setFormData(initialFormData);
    setIsSubmitted(false);
  };

  if (isSubmitted) {
    return (
      <section className="bg-black py-16">
        <div className="container mx-auto px-4">
          <div className="max-w-2xl mx-auto bg-gray-900 p-8 rounded-lg shadow-lg text-center">
            <div className="mb-6">
              <Check className="mx-auto text-gold-500" size={64} />
            </div>
            <h2 className="text-3xl font-bold mb-4 text-gold-500">Form Submitted Successfully!</h2>
            <p className="text-gray-300 mb-6">Thank you for your interest. We'll be in touch soon.</p>
            <button
              onClick={handleNewForm}
              className="bg-gold-500 hover:bg-gold-600 text-black font-bold py-3 px-6 rounded-full transition duration-300 shadow-md hover:shadow-lg"
            >
              Send New Form
            </button>
          </div>
        </div>
      </section>
    );
  }

  return (
    <div>
    <section className="bg-black py-16" id="contact">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center mb-8 text-gold-500">Contact Us</h2>
        <form onSubmit={handleSubmit} className="max-w-2xl mx-auto bg-gray-900 p-8 rounded-lg shadow-lg">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label htmlFor="fullName" className="block text-gold-500 font-semibold mb-2">Full Name</label>
              <input
                type="text"
                id="fullName"
                name="fullName"
                value={formData.fullName}
                onChange={handleInputChange}
                className="w-full px-4 py-2 border border-gold-500 text-gold-500 rounded-md focus:outline-none focus:ring-2 focus:ring-gold-500 transition duration-300"
                required
              />
            </div>
            <div>
              <label htmlFor="email" className="block text-gold-500 font-semibold mb-2">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                className="w-full px-4 py-2 border border-gold-500 text-gold-500 rounded-md focus:outline-none focus:ring-2 focus:ring-gold-500 transition duration-300"
                required
              />
            </div>
          </div>
          <div className="mt-6">
            <label htmlFor="phoneNumber" className="block text-gold-500 font-semibold mb-2">Phone Number</label>
            <input
              type="tel"
              id="phoneNumber"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleInputChange}
              className="w-full px-4 py-2 border border-gold-500 text-gold-500 rounded-md focus:outline-none focus:ring-2 focus:ring-gold-500 transition duration-300"
              required
            />
          </div>
          <div className="mt-6">
            <p className="block text-gold-500 font-semibold mb-3">Services Interested In</p>
            <div className="flex flex-wrap gap-4">
              {Object.keys(formData.services).map((service) => (
                <label key={service} className="inline-flex items-center bg-gray-800 px-4 py-2 rounded-full hover:bg-gray-700 transition duration-300">
                  <input
                    type="checkbox"
                    name={service}
                    checked={formData.services[service]}
                    onChange={handleServiceChange}
                    className="form-checkbox h-5 w-5 text-gold-500"
                  />
                  <span className="ml-2 text-gold-500 capitalize">{service}</span>
                </label>
              ))}
            </div>
          </div>
          {formData.services.tutoring && (
            <div className="mt-6">
              <p className="block text-gold-500 font-semibold mb-3">Tutoring Level</p>
              <div className="flex gap-4">
                {['A Level', 'GCSE'].map((level) => (
                  <label key={level} className="inline-flex items-center">
                    <input
                      type="radio"
                      name="tutoringLevel"
                      value={level}
                      checked={formData.tutoringLevel === level}
                      onChange={handleInputChange}
                      className="form-radio h-5 w-5 text-gold-500"
                    />
                    <span className="ml-2 text-gold-500">{level}</span>
                  </label>
                ))}
              </div>
            </div>
          )}
          {formData.services.tutoring && formData.tutoringLevel && (
            <div className="mt-6">
              <p className="block text-gold-500 font-semibold mb-3">Subjects</p>
              <div className="flex flex-wrap gap-2">
                {subjects[formData.tutoringLevel].map((subject) => (
                  <button
                    key={subject}
                    type="button"
                    onClick={() => handleSubjectChange(subject)}
                    className={`px-4 py-2 rounded-full text-sm font-semibold transition duration-300 ${
                      formData.subjects.includes(subject)
                        ? 'bg-gold-500 text-black hover:bg-gold-600'
                        : 'bg-gray-800 text-gold-500 hover:bg-gray-700'
                    }`}
                  >
                    {subject}
                  </button>
                ))}
              </div>
            </div>
          )}
          <div className="mt-6">
            <label htmlFor="notes" className="block text-gold-500 font-semibold mb-2">Additional Notes</label>
            <textarea
              id="notes"
              name="notes"
              value={formData.notes}
              onChange={handleInputChange}
              className="w-full px-4 py-2 border border-gold-500  text-gold-500 rounded-md focus:outline-none focus:ring-2 focus:ring-gold-500 transition duration-300"
              rows="4"
            ></textarea>
          </div>
          <div className="mt-8 text-center">
            <button
              type="submit"
              disabled={isLoading}
              className={`bg-gold-500 hover:bg-gold-600 text-black font-bold py-3 px-6 rounded-full transition duration-300 shadow-md hover:shadow-lg ${
                isLoading ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            >
              {isLoading ? (
                <div className="flex items-center justify-center">
                  <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-black mr-2"></div>
                  Submitting...
                </div>
              ) : (
                'Submit'
              )}
            </button>
          </div>
        </form>
      </div>
    </section>
          <AlternativeContact email="helixtutorss@gmail.com"/>
          </div>

  );
};
const AlternativeContact = ({ email }) => {
  return (
    <div className="bg-black py-12 text-white">
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto border border-gold-500 rounded-lg p-8 text-center">
          <h3 className="text-2xl font-bold mb-4 text-gold-500">Need a direct line?</h3>
          <p className="text-lg mb-6 text-gray-300">
            If you prefer, you can reach out to us directly via email.
          </p>
          <div className="inline-block bg-gold-500 hover:bg-gold-600 transition duration-300 rounded-full p-1">
            <a 
              href={`mailto:${email}`} 
              className="flex items-center px-6 py-3 text-black"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
              </svg>
              <span className="font-semibold">{email}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;