import React, { useState } from 'react';
import { FaGraduationCap, FaStethoscope, FaUserShield, FaPenFancy, FaHandsHelping, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';
import serviceImage1 from '../assets/serviceImage1.png';
import serviceImage2 from '../assets/serviceImage2.png';
import serviceImage3 from '../assets/serviceImage3.jpg';
import serviceImage4 from '../assets/serviceImage4.png';
import serviceImage5 from '../assets/serviceImage5.jpg';
const services = [
    {
        title: "GCSE & A Level Tuition",
        description: "Comprehensive tutoring tailored to help aspiring students excel in their GCSEs and A Levels.",
        icon: <FaGraduationCap className="text-gold-500 text-4xl" />,
        price: "GCSE £20/hour  |  A Level £25/hour",
        image: serviceImage1,
    },
    {
        title: "UCAT Mentorship",
        description: "Expert guidance and resources to ace the UCAT and secure a place in top medical schools.",
        icon: <FaStethoscope className="text-gold-500 text-4xl" />,
        price: "£30/hour",
        image: serviceImage2,
    },
    {
        title: "Medical School Interview Coaching",
        description: "Prepare with confidence for your medical school interviews with our in-depth coaching.",
        icon: <FaUserShield className="text-gold-500 text-4xl" />,
        price: "£45/hour",
        image: serviceImage3,
    },
    {
        title: "Personal Statement Support",
        description: "Craft a standout personal statement with our personalized support, helping you succeed in your university applications.",
        icon: <FaPenFancy className="text-gold-500 text-4xl" />,
        price: "£30/hour",
        image: serviceImage4,
    },
    {
        title: "Exclusive Mentoring Program",
        description: "Gain insights and advice from experienced medical students and professionals.",
        icon: <FaHandsHelping className="text-gold-500 text-4xl" />,
        price: "£15/hour (free for existing tuition students)",
        image: serviceImage5,
    },
];

const ServiceCard = ({ service, isMobile }) => (
    <div className={`relative group overflow-hidden rounded-lg shadow-lg bg-gray-900 border border-gold-500 ${isMobile ? 'h-full flex flex-col' : 'h-full'}`}>
        <div className={`relative ${isMobile ? 'h-40' : 'h-48'} min-h-[10rem] max-h-[12rem]`}>
            <img 
                src={service.image} 
                alt={service.title} 
                className="w-full h-full object-cover transition-transform duration-500 transform group-hover:scale-110"
            />
        </div>
        <div className={`bg-black bg-opacity-80 p-4 flex flex-col justify-between ${isMobile ? 'flex-grow' : 'absolute bottom-0 left-0 right-0 transform translate-y-full group-hover:translate-y-0 transition-transform duration-500'}`}>
            <div className="flex-grow">
                <div className="flex items-center justify-between mb-2">
                    <h3 className="text-xl font-semibold text-gold-500">{service.title}</h3>
                    <div className="text-gold-500 text-3xl">
                        {service.icon}
                    </div>
                </div>
                <p className="text-gold-300 text-sm">{service.description}</p>
            </div>
            <div className="bg-gold-500 text-black font-semibold p-2 text-center rounded mt-4">
                <p className="text-xl">{service.price}</p>
            </div>
        </div>
    </div>
);

const MobileCarousel = ({ services }) => {
    const [[page, direction], setPage] = useState([0, 0]);

    const paginate = (newDirection) => {
        setPage([page + newDirection, newDirection]);
    };

    const currentIndex = ((page % services.length) + services.length) % services.length;

    return (
        <div className="relative w-full overflow-hidden" style={{ height: '450px' }}>
            <AnimatePresence initial={false} custom={direction}>
                <motion.div
                    key={page}
                    custom={direction}
                    variants={{
                        enter: (direction) => ({ x: direction > 0 ? 1000 : -1000, opacity: 0 }),
                        center: { zIndex: 1, x: 0, opacity: 1 },
                        exit: (direction) => ({ zIndex: 0, x: direction < 0 ? 1000 : -1000, opacity: 0 }),
                    }}
                    initial="enter"
                    animate="center"
                    exit="exit"
                    transition={{ type: 'tween', duration: 0.5 }}
                    className="w-full h-full absolute"
                >
                    <ServiceCard service={services[currentIndex]} isMobile={true} />
                </motion.div>
            </AnimatePresence>
            <button 
                onClick={() => paginate(-1)} 
                className="absolute top-20 left-2 transform -translate-y-1/2 bg-gold-500 p-2 rounded-full z-10"
            >
                <FaChevronLeft className="text-black" />
            </button>
            <button 
                onClick={() => paginate(1)} 
                className="absolute top-20 right-2 transform -translate-y-1/2 bg-gold-500 p-2 rounded-full z-10"
            >
                <FaChevronRight className="text-black" />
            </button>
        </div>
    );
};

const Services = () => {
    return (
        <div className="bg-gradient-to-b from-black via-gray-900 to-black py-16" id="services">
            <div className="container mx-auto px-4">
                <h2 className="text-4xl text-center text-gold-500 font-bold mb-12">Our Medical Tutoring Services</h2>
                <div className="hidden sm:grid sm:grid-cols-2 lg:grid-cols-3 gap-8">
                    {services.map((service, index) => (
                        <ServiceCard key={index} service={service} isMobile={false} />
                    ))}
                </div>
                <div className="sm:hidden">
                    <MobileCarousel services={services} />
                </div>
                {/* Pricing Note */}
                <div className="mt-12 text-center text-gold-300">
                    <p className="text-lg">
                        Prices are negotiable. Please <span className="text-gold-500 font-bold">contact us</span> for more details and to discuss tailored plans for your needs.
                    </p>
                    <p className="text-lg mt-4">
                        We have discounts available for students who fit <span className="font-bold">Widening Participation</span> requirements.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Services;