import React, { useRef, useEffect } from 'react';
import { Typed } from 'react-typed';
import { FaChevronDown } from 'react-icons/fa';
import DNAHelix from './DNAHelix'; // Assuming DNAHelix is in the same directory

const Hero = () => {
  const typedRef = useRef(null);

  useEffect(() => {
    if (typedRef.current) {
      const typed = new Typed(typedRef.current, {
        strings: ['Driven by Excellence', 'Defined by Results', 'Empowering Future Leaders'],
        typeSpeed: 40,
        backSpeed: 50,
        loop: true,
      });

      return () => {
        typed.destroy();
      };
    }
  }, []);

  const scrollToContent = () => {
    window.scrollTo({
      top: window.innerHeight,
      behavior: 'smooth',
    });
  };

  return (
    <div
      className="min-h-screen bg-gradient-to-b from-black via-gray-900 to-black flex flex-col justify-center items-center text-gold-500 relative overflow-hidden pt-16 pb-24 sm:pb-32"
      id="home"
    >
      <div className="absolute inset-0 bg-[radial-gradient(circle,_rgba(255,215,0,0.1)_1px,_transparent_1px)] [background-size:20px_20px]"></div>

      {/* DNA Helix SVG */}
      <DNAHelix />

      <div className="max-w-4xl mx-auto text-center px-4 z-10">
        <h1 className="text-5xl sm:text-6xl md:text-7xl font-bold mb-6 tracking-tight text-gold-500">
          Helix Tutors
        </h1>
        <div className="flex justify-center items-center mb-8">
          <span
            ref={typedRef}
            className="text-2xl sm:text-3xl md:text-4xl font-semibold text-gold-400"
          ></span>
        </div>
        <p className="text-xl md:text-2xl mb-10 text-gold-300">
          Guiding future doctors to excel in their studies with expert tutoring from top medical students.
        </p>
        <button
          className="bg-gold-500 hover:bg-gold-600 text-black font-bold py-4 px-8 rounded-full text-lg transition duration-300 shadow-lg hover:shadow-xl transform hover:scale-105"
          onClick={scrollToContent}
        >
          Enroll with Us
        </button>
      </div>

      <div className="absolute bottom-8 sm:bottom-16 left-1/2 transform -translate-x-1/2">
        <button
          onClick={scrollToContent}
          className="flex flex-col items-center text-gold-400 hover:text-gold-600 transition duration-300"
        >
          <FaChevronDown size={28} className="animate-bounce mb-2" />
          <span className="text-xs sm:text-sm font-semibold tracking-wide">
            Explore More
          </span>
        </button>
      </div>
    </div>
  );
};

export default Hero;