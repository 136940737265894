import React, { useState, useEffect } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import logoPart1 from '../assets/logopart1-Photoroom.png';
import logoPart2 from '../assets/logopart2-Photoroom.png';

const Menu = [
  {
    id: 1,
    name: "Home",
    link: "#home",
  },
  {
    id: 2,
    name: "Courses",
    link: "#services",
  },
  {
    id: 3,
    name: "About",
    link: "#about",
  },
  {
    id: 4,
    name: "Team",
    link: "#team",
  },
  {
    id: 5,
    name: "Contact",
    link: "#contact",
  },
];

const Navbar = () => {
  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  const scrollToSection = (e, sectionId) => {
    e.preventDefault();
    const section = document.querySelector(sectionId);
    if (section) {
      const yOffset = -80; // Adjust this value based on your navbar height
      const y = section.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
    setNav(false); // Close the mobile menu after clicking
  };

  useEffect(() => {
    // Prevent scrolling when mobile menu is open
    if (nav) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [nav]);

  return (
    <div className="bg-black bg-opacity-90 shadow-md text-gold-500 fixed top-0 left-0 w-full z-50">
      <div className="container mx-auto flex justify-between items-center py-4 px-6">
        {/* Logo section */}
        <div className="flex items-center">
          <img src={logoPart1} alt="logo" className="w-20 h-12" />
          <img src={logoPart2} alt="logo" className="w-30 h-10" />
        </div>

        {/* Link section */}
        <div className="hidden md:flex items-center gap-6">
          {Menu.map((menu) => (
            <a
              key={menu.id}
              href={menu.link}
              onClick={(e) => scrollToSection(e, menu.link)}
              className="text-lg hover:text-gold-300 duration-200"
            >
              {menu.name}
            </a>
          ))}
          <a 
            className="bg-gold-500 hover:bg-gold-600 text-black px-4 py-2 rounded-full flex items-center gap-2 font-semibold transition duration-300" 
            href="#contact"
            onClick={(e) => scrollToSection(e, '#contact')}
          >
            Sign Up
          </a>
        </div>

        {/* Mobile Menu Button */}
        <div onClick={handleNav} className="block md:hidden">
          {nav ? <AiOutlineClose size={24} className="text-gold-500" /> : <AiOutlineMenu size={24} className="text-gold-500" />}
        </div>

        {/* Mobile Menu */}
        <div
          className={`${
            nav ? 'left-0' : '-left-full'
          } fixed top-0 w-full h-full bg-black text-gold-500 p-6 z-50 transition-all duration-300 md:hidden flex flex-col`}
        >
          <div className="flex justify-between items-center mb-6">
            <h1 className="font-bold text-2xl text-gold-500">HelixTutors</h1>
            <div onClick={handleNav}>
              <AiOutlineClose size={24} className="text-gold-500" />
            </div>
          </div>
          <ul className="space-y-6 flex-grow">
            {Menu.map((menu) => (
              <li key={menu.id}>
                <a 
                  href={menu.link} 
                  onClick={(e) => scrollToSection(e, menu.link)}
                  className="text-lg hover:text-gold-300"
                >
                  {menu.name}
                </a>
              </li>
            ))}
          </ul>
          <a 
            className="mt-auto bg-gold-500 hover:bg-gold-600 text-black px-4 py-2 rounded-full w-full font-semibold transition duration-300 text-center" 
            href="#contact"
            onClick={(e) => scrollToSection(e, '#contact')}
          >
            Sign Up
          </a>
        </div>
      </div>
    </div>
  );
};

export default Navbar;