import React from 'react';

const DNAHelix = () => {
  return (
    <svg
      className="absolute inset-0 w-full h-full opacity-30"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid slice"
    >
      <defs>
      <linearGradient id="gradient1" x1="0%" y1="0%" x2="100%" y2="0%">
  <stop offset="0%" stopColor="#D4AF37" />
  <stop offset="100%" stopColor="#B8860B" />
</linearGradient>
<linearGradient id="gradient2" x1="0%" y1="0%" x2="100%" y2="0%">
  <stop offset="0%" stopColor="#FFD700" />
  <stop offset="100%" stopColor="#DAA520" />
</linearGradient>
      </defs>
      
      <g className="animate-spin-slow" style={{ transformOrigin: 'center', animation: 'spin 20s linear infinite' }}>
        <path
          d="M50,10 Q70,25 50,40 T50,70 T50,100"
          fill="none"
          stroke="url(#gradient1)"
          strokeWidth="0.5"
          className="animate-dash"
          style={{ animation: 'dash 5s linear infinite' }}
        />
        <path
          d="M50,10 Q30,25 50,40 T50,70 T50,100"
          fill="none"
          stroke="url(#gradient2)"
          strokeWidth="0.5"
          className="animate-dash"
          style={{ animation: 'dash 5s linear infinite reverse' }}
        />
        
        {[...Array(5)].map((_, i) => (
          <g key={i} className="animate-pulse">
          <circle cx="50" cy={10 + i * 20} r="1" fill="#FFD700" />
          <circle cx="50" cy={20 + i * 20} r="1" fill="#D4AF37" />
        </g>
        ))}
      </g>
      
      <style>
        {`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
          @keyframes dash {
            to {
              stroke-dashoffset: 100;
            }
          }
          .animate-spin-slow {
            animation: spin 20s linear infinite;
          }
          .animate-dash {
            stroke-dasharray: 10;
            animation: dash 5s linear infinite;
          }
          .animate-pulse {
            animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
          }
          @keyframes pulse {
            0%, 100% { opacity: 1; }
            50% { opacity: 0.5; }
          }
        `}
      </style>
    </svg>
  );
};

export default DNAHelix;