import React from "react";
import { FaUserGraduate, FaClipboardList, FaStethoscope } from 'react-icons/fa';

const WhyUs = () => {
    return (
        <div className="p-6 md:p-10 font-sans bg-gradient-to-b from-black to-gray-900 text-gold-500" id="about">
            <div className="text-center mb-8"> 
                <h3 className="text-gold-400 font-bold uppercase mb-2 text-sm md:text-base">Why Choose HelixTutors?</h3>
                <h2 className="font-bold text-xl md:text-3xl mb-4 text-gold-500">Empower Your Medical Journey with Top-tier Support</h2>
                <p className="text-gold-300 text-sm md:text-base max-w-2xl mx-auto">
                    At HelixTutors, our mission is to guide aspiring medical students toward academic excellence with personalized support from top medical students at leading universities. Learn how we provide affordable, tailored tutoring services that fit your needs.
                </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
                <div className="bg-gray-900 p-6 rounded-lg shadow-lg flex items-center justify-between border border-gold-500">
                    <div className="flex-1">
                        <h4 className="font-bold text-lg mb-2 text-gold-500">Personalized Academic Support</h4>
                        <p className="text-gold-300">
                            We offer customized tutoring sessions in Biology, Chemistry, and Maths at GCSE level, and focused support for A-level students in Biology and Chemistry. Each session is tailored to meet your individual learning needs and goals.
                        </p>
                    </div>
                    <FaUserGraduate className="text-4xl text-gold-400 ml-4 flex-shrink-0" />
                </div>
                <div className="bg-gray-900 p-6 rounded-lg shadow-lg flex items-center justify-between border border-gold-500">
                    <div className="flex-1">
                        <h4 className="font-bold text-lg mb-2 text-gold-500">Expert Personal Statement Guidance</h4>
                        <p className="text-gold-300">
                            Applying to medical school? Our tutors will guide you through the entire process, ensuring your personal statement highlights your strengths and stands out in a competitive field.
                        </p>
                    </div>
                    <FaClipboardList className="text-4xl text-gold-400 ml-4 flex-shrink-0" />
                </div>
                <div className="bg-gray-900 p-6 rounded-lg shadow-lg flex items-center justify-between border border-gold-500">
                    <div className="flex-1">
                        <h4 className="font-bold text-lg mb-2 text-gold-500">Tutors from Top Medical Schools</h4>
                        <p className="text-gold-300">
                            Our tutors come from prestigious universities like King's College and York Medical School. They provide you with invaluable insights and personalized academic support, helping you achieve success in your studies and applications.
                        </p>
                    </div>
                    <FaStethoscope className="text-4xl text-gold-400 ml-4 flex-shrink-0" />
                </div>
                <div className="bg-gray-900 p-6 rounded-lg shadow-lg flex items-center justify-between border border-gold-500">
                    <div className="flex-1">
                        <h4 className="font-bold text-lg mb-2 text-gold-500">Flexible and Affordable Tutoring</h4>
                        <p className="text-gold-300">
                            At HelixTutors, we understand the demands of your schedule. That's why we offer flexible tutoring options that fit around your lifestyle, all at an affordable price to ensure that top-quality support is accessible to everyone.
                        </p>
                    </div>
                    <FaUserGraduate className="text-4xl text-gold-400 ml-4 flex-shrink-0" />
                </div>
            </div>
        </div>
    );
};

export default WhyUs;