import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Team from './components/Team';
import Testimonials from './components/Testimonials';
import Footer from './components/Footer';
import WhyUs from './components/About';
import Services from './components/Services';
// import ScrollIndicator from './components/ScrollIndicator';
import Contact from './components/Contact';

function App() {
  return (
    <div>
      {/* <ScrollIndicator /> */}
      <Navbar />
      <Hero />
        <WhyUs />
        <Services />
      <div id = "team">
      <Team />
      </div>
      <Testimonials />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
